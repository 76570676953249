import cookie from 'js-cookie';
import ApiService from "./ApiService"


function setUserTimezoneIfNeeded() {
  if (IS_AUTHENTICATED === true) {
    const currTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (currTimezone !== USER_TIMEZONE) {
      ApiService.userSetTimezone(currTimezone);
    }
  }
}

function showMembershipCancelledModalIfNeeded() {
  const shouldShow = (
      USER_SUBSCRIPTION_STATUS === 'C' &&
      cookie.get('wannago.cancelledModalShown') === undefined
    );

  if (shouldShow) {
    $('#membership-cancelled-modal').modal('show');
    cookie.set('wannago.cancelledModalShown', 1);
  }
}

// Run on every page load
$(document).ready(function() {
  setUserTimezoneIfNeeded();
  showMembershipCancelledModalIfNeeded();
});
